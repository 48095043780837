<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Rechazar producto</p>
        <button class="delete" aria-label="close" @click="close"></button>
      </header>
      <section class="modal-card-body">
        <div class="field">
          <label class="label">Motivo de rechazo:</label>
          <div class="control">
            <textarea class="textarea is-info" placeholder="Motivo de rechazo" v-model="rejectedMessage"></textarea>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" @click="close">Cancelar</button>
        <button class="button button-givu" @click="submit" :disabled="!rejectedMessage || loadingStatus" :class="{ 'is-loading': loadingStatus}">Confirmar</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalChangeStatus',
  data () {
    return {
      rejectedMessage: '',
      loadingStatus: false
    }
  },
  props: {
    close: {
      type: Function,
      default: () => {}
    },
    action: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    async submit () {
      try {
        this.loadingStatus = true

        await this.action(this.rejectedMessage)
      } finally {
        this.loadingStatus = false
        this.close()
      }
    }
  }
}
</script>
